import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function Landing2Parts() {
  return (
    <>
      <div className="features-2">
        <Container>
          <Row className="align-items-center">
            <Col className="mr-auto text-left" lg="3" md="8">
              <h1 className="title">What You Get</h1>
              <p className="description">
                Lightyears beyond WordPress or website builder sites like GoDaddy or Wix.  
              </p>
              <Button  
                className="btn-simple"
                color="info"
                href="/contact-me"
              >
                Register Free
              </Button>
            </Col>
            <Col lg="8" md="12">
              <Row>
                <Col lg="4" md="4">
                  <div className="info text-left bg-info">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-satisfied" />
                    </div>
                    <h4 className="info-title">Research</h4>
                    <p className="description">
                      I research your company, your customers and your competition before I start.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-danger info-raised">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-palette" />
                    </div>
                    <h4 className="info-title">Thoughtful Design</h4>
                    <p className="description">
                      Rapidly progress from wire-frame, to MVP, to a testable prototye and into production.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-default">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-spaceship" />
                    </div>
                    <h4 className="info-title">Great Performance</h4>
                    <p className="description">
                      Lightning fast serverless technology from the worlds largest cloud provider.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4">
                  <div className="info text-left bg-primary">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-user-run" />
                    </div>
                    <h4 className="info-title">Fast Development</h4>
                    <p className="description">
                      Frameworks, Premium Looks, and Continuous Deployments speed time to market.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-warning info-raised">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-bulb-63" />
                    </div>
                    <h4 className="info-title">Better Decisions</h4>
                    <p className="description">
                     Analytics and API intergration with social media to help you make better business decisions.
                    </p>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="info text-left bg-info">
                    <div className="icon icon-white">
                      <i className="tim-icons icon-paper" />
                    </div>
                    <h4 className="info-title text-white">Get Organized</h4>
                    <p className="description text-white">
                      All your information in one safe place thats easily accessible.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
