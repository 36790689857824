import React from "react";

// reactstrap components
import {
  Button,
  Container
} from "reactstrap";




export default function DemoFooter() {
  return (
    <>
         <footer className="footer" data-background-color="black">
            <Container>
              <a
                
                className="footer-brand"
                href="/Presentation"
               
              >
              <img src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"   alt="Logo KrisCodes2"  height="40rem" />
              </a>
              <ul className="pull-center">
                <li>
                  <a
                    href="/landing"
                   
                    className="ml-1"
                  >
                    Benefits
                  </a>
                </li>
                <li>
                  <a
                    href="/about-me"
                   
                    className="ml-1"
                  >
                    About Me
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-me"
                   
                    className="ml-1"
                  >
                    Contact Me
                  </a>
                </li>
              </ul>
              <ul className="social-buttons pull-right">
                <li>
                  <Button
                    className="btn-icon btn-round"
                    color="github"
                    href="https://github.com/kriscodes2"
                    target="_blank"
                  >
                    <i  alt="github icon"  rel="noreferrer"   className="fab fa-github" />
                  </Button>
                </li>
                <li>
                  <Button
                    className="btn-icon btn-round ml-1"
                    color="linkedin"
                    href="https://www.linkedin.com/in/krischristopher/"
                    target="_blank"
                  >
                    <i  alt="linkedin icon" rel="noreferrer"   className="fab fa-linkedin" />
                  </Button>
                </li>
              </ul>
            </Container>
          </footer>
    </>
  );
}