import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Polyline, InfoWindow, Marker } from "@react-google-maps/api";

const DistanceMap = () => {
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [selected, setSelected] = useState({});
  const [miles, setMiles] = useState(null);
  const mapRef = useRef(null);

  const center = {
    lat: 29.285311,
    lng: -94.789453
  };

  const onSelect = item => {
    setSelected(item);
  };

  const containerStyle = {
    height: "55vh",
    width: "100%"
  };

  const locations = [
    {
      name: "My Location",
      location: {
        lat: latitude,
        lng: longitude
      }
    },
    {
      name: "KrisCodes2 Location",
      location: {
        lat: 18.458267,
        lng: -67.164264
      }
    }
  ];

  const paths = [
    {
      lat: latitude,
      lng: longitude
    },
    {
      lat: 18.458267,
      lng: -67.164264
    }
  ];

  const polylineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };

  const mapOptions = {
    mapTypeId: 'satellite',
    styles: [
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }]
      },
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#e9e9e9" }, { visibility: "on" }]
      },
      {
        featureType: "water",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }]
      }
    ]
  };

  useEffect(() => {
    const geoOptions = {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 27000,
    };

    function success(pos) {
      setLat(pos.coords.latitude);
      setLng(pos.coords.longitude);
      setAccuracy(pos.coords.accuracy);
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, geoOptions);

    if (latitude && longitude) {
      const lat1 = latitude;
      const lon1 = longitude;
      const lat2 = 18.458267;
      const lon2 = -67.164264;
      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1); // deg2rad below
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      const miles = Math.ceil(d / 1.609);
      setMiles(miles);
    }

    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    return () => {
      setMiles(null);
    };
  }, [latitude, longitude]);

  useEffect(() => {
    if (mapRef.current && latitude && longitude) {
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach(path => {
        bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [paths]);
 
  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={3}
        options={mapOptions}
        onLoad={map => (mapRef.current = map)}
      >
        {locations.map((item, index) => (
          <Marker
            key={index}
            position={item.location}
            onClick={() => onSelect(item)}
          />
        ))}
        {selected.location && (
          <InfoWindow
            position={selected.location}
            clickable={true}
            onCloseClick={() => setSelected({})}
          >
            <div>{selected.name}</div>
          </InfoWindow>
        )}
        <Polyline path={paths} options={polylineOptions} />
      </GoogleMap>
      <h4 className="text-light">{miles} miles apart? No Worries. I make remote work a breeze. </h4>
    </>
  );
};

export default DistanceMap;
