
import React from "react";
import "assets/css/blk-design-system-pro-react.css";

// reactstrap components
import { Button, Container, Card, CardImg } from "reactstrap";

export default function LandingHeader() {
  const scrollPage = (e) => {
    e.preventDefault();
    document.getElementById("pricing").scrollIntoView();
  };
  return (
    <>
    <div >
      <Card style={{  
  backgroundImage: "url(" + "https://kriscodes2.s3.amazonaws.com/img/brand-trust.jpg" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
  <Card  style={{  
  width:"20rem",  
  backgroundImage: "url(" + "https://kriscodes2.s3.amazonaws.com/img/webp/kris-codes-2-packages.webp" + ")",
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}} className="mx-auto pt-5 pb-5 mb-5 mt-5" width="5rem" >
          <div className="content-center brand mx-auto text-center align-center">
            <h1 className="mt-0 pt-0 h1-seo">
              Kris Codes <b className="text-warning">Too</b>
            </h1>
            <h2 className="text-info" > I Mostly</h2>
            <div className="mx-auto" >
                <li className="mx-auto text-white" >
                Build Brand Trust
                </li>
                <li className="mx-auto text-white" >
                Increase Traffic
                </li>
                <li className="mx-auto text-white" >
                Deliver Quaility Analytics
                </li>
                <li className="mx-auto text-white" >
                Make It Simple For You
                </li>
            </div>
            <Button
              className="btn-simple mt-4"
              color="info"
              href="/contact-me"
              size="lg"
            >
              Start Free
            </Button>
            {/* <Button
              className="btn-link mt-4 d-block"
              color="warning"
              href="/pricing"
              size="lg"
            >
              <u>Show pricing</u>
            </Button> */}
          </div>
          </Card>
          </Card>
      </div>

    </>
  );
}
