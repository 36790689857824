import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

export default function FreeDemo() {
  return (
    <>
      <div className="d-flex justify-content-center section section-free-demo mx-auto">
        <Container>
          <Row className="mx-auto">
            <Col lg="12" md="12" className="mx-auto">
              <div className="section-description">
                <em className="title ml-2 mb-0 pb-0 color-red">
                  As Advertised
                </em>
                <h6 className="ml-2">Complete packages starting at</h6>
                <h1 className="title mt-0 pt-0 ml-4 text-success">$2,999</h1>
                <p className="description mb-4">
                  <i className="text-warning">Limited {"  "}time only</i> - Get
                  a complete website package for 90% off retail value. <br />
                  30 day no questions asked money back guarantee.
                </p>
                <div className="button mx-auto">
                  <Button
                    className="btn-round"
                    color="primary"
                    href="/contact-me"
                  >
                    Register Free
                  </Button>
                  <Button
                    className="btn-round btn-simple"
                    color="primary"
                    href="/landing"
                  >
                    See Details   
                  </Button>
                </div>
                <div className="clearfix" />
              </div>

              <Row className="d-flex justify-content-center">
                <Col className="pt-5" md="4">
                  <Card className="card-pricing card-plain card-info ml-0 pl-0">
                    <CardBody>
                      <h2 className="title text-secondary mx-auto">
                        Starter Package
                      </h2>
                      <ul>
                        <li className="mr-0 pr-0">
                          <strong>5</strong> Custom Pages
                        </li>
                        <li>
                          <strong>INCLUDES</strong> Domain Name{" "}
                          <strong> 1yr</strong>
                        </li>
                        <li>
                          <strong>FREE</strong> SSL Certificate
                        </li>
                        <li>
                          <strong>SEO</strong> Optimization
                        </li>
                        <li>
                          <strong>CDN</strong> Globally Available
                        </li>
                        <li>
                          Traffic <strong>Analytics</strong>
                        </li>
                        <li>
                          <i className="tim-icons icon-simple-remove text-warning" />{" "}
                          eCommerce
                        </li>
                        <li>
                          <i className="tim-icons icon-simple-remove text-warning" />{" "}
                          Premium Support
                        </li>
                        <Button
                          className="btn-round btn-simple"
                          color="success"
                          href="/contact-me"
                        >
                          Products
                        </Button>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="pt-5 px-3" md="4">
                  <Card className="card-pricing card-plain card-info">
                    <CardBody>
                      <h2 className="title text-secondary">+PLUS Package</h2>
                      <ul>
                        <li>Adds To Starter Package</li>
                        <li>
                          <strong>7</strong> Custom Pages
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          eCommerce
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Premium Support
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Custom Logo
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          6 Addition Pages
                        </li>
                        <li>
                          <i className="tim-icons icon-simple-remove text-warning" />{" "}
                          Custom Analytics Dashboard
                        </li>
                        <li>
                          <i className="tim-icons icon-simple-remove text-warning" />{" "}
                          Registered Logo Full Rights
                        </li>
                        <Button
                          className="btn-round btn-simple"
                          color="success"
                          href="/contact-me"
                        >
                        Products
                        </Button>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="pt-5 px-3" md="4">
                  <Card className="card-pricing card-plain card-info">
                    <CardBody>
                      <h2 className="title text-success">PRO Package</h2>
                      <ul>
                        <li>Adds To +Plus Package</li>
                        <li>
                          <strong>10</strong> Custom Pages
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Custom Intro Video
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Custom Analytics Dashboard
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Unlimited Bandwidth
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Unlimited Updates
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Linked to Social Media
                        </li>
                        <li>
                          <i className="tim-icons icon-check-2 text-success" />{" "}
                          Unlimted Products
                        </li>
                        <Button
                          className="btn-round btn-simple"
                          color="success"
                          href="/contact-me"
                        >
                          Products
                        </Button>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}
