import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ReactGA from "react-ga";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// presentation pages
import Index from "views/Index.js";
import Presentation from "views/Presentation.js";
import Sections from "views/Sections.js";
// example pages

import Error404 from "views/examples/Error404.js";
import Error500 from "views/examples/Error500.js";
// My Components
import ContactMe from "views/examples/ContactMe.js";
import AboutMe from "views/examples/AboutMe.js";
import Landing from "views/Landing.js";
import GettingToKnowYou from "views/GettingToKnowYou";
import BusinessSnapshot from "views/BusinessSnapshot.js";
import Resume from "views/examples/Resume.js";

ReactGA.initialize("G-7FW7ZZY0ZR", {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: "kriscodes2",
  },
});

ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/index" element={<Index />} />
      <Route path="/presentation" element={<Presentation />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/landing-page" element={<Presentation />} />
      <Route path="/404-error" element={<Error404 />} />
      <Route path="/500-error" element={<Error500 />} />
      <Route path="/" element={<Navigate to="/presentation" replace />} />
      <Route path="/contact-me" element={<ContactMe />} />
      <Route path="/about-me" element={<AboutMe />} />
      <Route path="/Landing" element={<Landing />} />
      <Route path="/business-profile" element={<GettingToKnowYou />} />
      <Route path="/Presentation" element={<Presentation />} />
      <Route path="/business-plan" element={<BusinessSnapshot />} />
    </Routes>
  </BrowserRouter>
);
