import React from "react";
// navbar
import ColorNavbar from "components/Navbars/ColorNavbar";
//header
import LandingHeader from "components/Headers/LandingHeader";
// body
import Landing1Need from "./LandingSections/Landing1Need";
import Landing2Parts from "./LandingSections/Landing2Parts";
// footer
import DemoFooter from "components/Footers/MyFooter";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  ListGroupItem,
  ListGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import GettingToKnowYou from "./GettingToKnowYou";

export default function Landing() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("landing");
    return function cleanup() {
      document.body.classList.remove("landing");
    };
  }, []);

  return (
    <>
      <ColorNavbar />
      <LandingHeader />
      <div className="wrapper" ref={wrapper} id="landing">
        
        <div className="space-110" />
        <Landing2Parts />
        <div className="space-110" />
    
        <div className="space-110" />
        <Landing1Need />

        <DemoFooter />
      </div>
    </>
  );
}
