import React, { useState } from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ContactHeader from "views/ContactSections/ContactHeader";
import DemoFooter from "components/Footers/MyFooter.js";
import DistanceMap from "components/auth/maps";

const ContactMe = () => {
  // Modal open state
  const [modal, setModal] = useState(false);

  return (
    <>
      <ColorNavbar />
      <ContactHeader />
      <div className="main">
        <Container fluid>
          <Row className="infos my-auto">
            <Col lg="3">
              <div className="info info-hover">
                <div className="icon icon-info">
                  <div
                    alt="..."
                    className=""
                    style={{
                      width: "100%",
                      marginTop: "80px",
                      marginLeft: "0px",
                      padding: "20px",
                      marginRight: "0px",
                      alignContent: "center",
                    }}
                  >
                    <video
                      loop={true}
                      type="video/mp4"
                      aria-label="Working remotely from the caribbean"
                      autoPlay={true}
                      className="mt-auto mx-auto"
                      width="100%"
                      alt="Working remotely from the caribbean"
                      src="https://kriscodes2.s3.amazonaws.com/video/aquadilla.mp4 "
                    />
                  </div>
                  <br />
                  <i className="tim-icons icon-square-pin" />
                </div>
                {modal && (
                  <Card>
                    <DistanceMap />
                  </Card>
                )}
                <div>
                  <h4 className="info-title">Distance From Me To you</h4>
                  <p className="description">Aquadilla, Puerto Rico</p>
                  <Button color="primary" onClick={() => setModal(true)}>
                    View Secret Hideout
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="3">
              <div className="info info-hover">
                <div className="icon icon-info">
                  <img
                    alt="..."
                    className=""
                    style={{
                      width: "250px",
                      marginTop: "60px",
                      marginLeft: "0px",
                      padding: "20px",
                      marginRight: "0px",
                      alignContent: "center",
                    }}
                    src="https://kriscodes2.s3.amazonaws.com/img/email.jpg"
                  />
                  <br />
                  <i className="tim-icons icon-email-85" />
                </div>
                <h4 className="info-title">Email</h4>
                <address>
                  <p className="description">
                    <a
                      className="text-info"
                      href="mailto:kris923@gmail.com?subject=Inquiry&body=Hello%20Kris,%0D%0A%0D%0ALets%20link%20up.%0D%0AMy%20name%20is%20.....%20."
                    >
                      Click to Email
                    </a>
                  </p>
                </address>
              </div>
            </Col>
            <Col lg="3">
              <div className="info info-hover">
                <div className="icon icon-warning">
                  <img
                    alt="ask-me-anything?"
                    className=""
                    style={{
                      width: "350px",
                      marginTop: "15px",
                      marginLeft: "0px",
                      padding: "20px",
                      marginRight: "0px",
                      alignContent: "center",
                    }}
                    src="https://kriscodes2.s3.amazonaws.com/img/askmeanything.png"
                  />
                  <br />
                  <i className="tim-icons icon-mobile" />
                </div>
                <h4 className="info-title text-white description">
                  Call or Text
                </h4>
                <a href="tel:+17876316775">
                  <p className="description text-info">Click to Call Now</p>
                </a>
                <a href="sms:+17876316775">
                  <p className="description text-info">Click to Text Me</p>
                </a>
              </div>
            </Col>
            <Col lg="3">
              <div className="info info-hover">
                <div className="icon icon-success">
                  <img
                    alt="..."
                    className=""
                    style={{
                      width: "250px",
                      marginTop: "15px",
                      marginLeft: "0px",
                      padding: "20px",
                      marginRight: "0px",
                      alignContent: "center",
                    }}
                    src="https://kriscodes2.s3.amazonaws.com/img/webp/kris-christopher.webp "
                  />
                  <br />

                  <i className="tim-icons icon-single-02" />
                </div>
                <a
                  href="https://kriscodes2.s3.amazonaws.com/files/Resume.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4 className="info-title text-info mb-0 pb-0">
                    Click for Resume
                  </h4>{" "}
                </a>
              </div>

              <Row
                className="mx-auto text-center mt-0"
                style={{ width: "100%" }}
              >
                <div className="mx-auto">
                  <Button
                    size="sm"
                    className="btn-icon btn-round"
                    color="github"
                    href="https://github.com/kriscodes2"
                  >
                    <i
                      alt="github icon"
                      rel="noreferrer"
                      className="fab fa-github"
                    />
                  </Button>
                  <Button
                    size="sm"
                    className="btn-icon btn-round ml-1"
                    color="linkedin"
                    href="https://www.linkedin.com/in/krischristopher/"
                  >
                    <i
                      alt="linkedin icon"
                      rel="noreferrer"
                      className="fab fa-linkedin"
                    />
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
};

export default ContactMe;
