import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthNavbar from "./AuthNavbar";

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [myUser, setMyUser] = useState(null);

  // useEffect(() => {
  //   checkUser();
  //   Hub.listen("auth", (data) => {
  //     const { payload } = data;
  //     if (payload.event === "signOut") {
  //       setMyUser(null);
  //     }
  //   });
  //   return () => {
  //     Hub.remove("auth");
  //   };
  // }, []);

  async function checkUser() {
    try {
      const data = {username: "user"} // await Auth.currentUserPoolUser();
      const userInfo = data.username ;
      console.log( userInfo );
      setMyUser( userInfo );
    } catch (err) {
      console.log("Whoopsy, looks like there is", err);
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-dark");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      window.scrollTo(0, 0);
    }
  };

  return myUser ? (
  <AuthNavbar />
  ) : (
    <>
      <Navbar
        dark
        className={
          "navbar sticky-top justify-content-sm-start fixed-top " + navbarColor
        }
        expand="lg"
      >
        <div className="navbar-translate">
          <Link
            onMouseEnter={(e) => scrollToTop(e)}
            id="tooltip6619950104"
            className="ml-0 pl-0 d-flex justify-content-sm-start"
            to="/landing-page"
          >
            <img
              className="ml-0 pl-0 d-flex justify-content-sm-start"
              src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"
              alt="Logo KrisCodes2"
              height="25rem"
            />
          </Link>
          <button className="navbar-toggler" id="navigation">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <UncontrolledCollapse navbar toggler="#navigation">
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/landing-page">
                  <img
                    src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"
                    alt="into video for Kris Codes 2"
                  />
                </Link>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="navigation">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
          <NavItem>
              <Button
                className="nav-link"
                color="default"
                href="/about-me"
                size="sm"
              >
                <p>About Me</p>
              </Button>
            </NavItem>
            <NavItem>
              <Button
                className="nav-link"
                color="default"
                href="/contact-me"
                size="sm"
              >
                <p>Get In Touch</p>
              </Button>
            </NavItem>
            <NavItem>
              <Button
                className="nav-link"
                color="default"
                href="/landing"
                size="sm"
              >
                <p>Learn More</p>
              </Button>
            </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
