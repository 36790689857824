import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardFooter,
  Media,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function Landing1Need() {
  return (
    <>
      <div>
        <div className="blogs-7 header-wrapper">
          <Container>
            <h1 className="title mx-auto text-white">Solutions For A Digital World</h1>
            <Row>
              <Col md="7">
                <Card className="card-blog card-background">
                  <CardBody>
                    <div
                      style={{
                        zIndex: "-1",   
                        right: "0",       
                        left: "0",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        display: "inline-block",
                      }}
                    >
                      <video
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                          height: "100%",
                          left: "0",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          height: "100%",
                          display: "inline-block",
                          filter: "brightness(0.4)"
                        }}
                        autoPlay
                        loop
                        
                      >
                        <source
                          src="https://kriscodes2.s3.amazonaws.com/video/more-users.mp4 "
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <h2 className="description text-white">Get seen by</h2>
                    <h1 className="title text-white">More Customers</h1>
                    <br />
                    <Button
                      className="btn-simple btn-neutral text-white"
                      color="default"
                      href="/contact-me"
                    >
                      how to get seen
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="5">
              <Card className="card-blog card-background">
                  <CardBody>
                    <div
                      style={{
                        zIndex: "-1",   
                        right: "0",       
                        left: "0",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        display: "inline-block",
                      }}
                    >
                      <video
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                          height: "100%",
                          left: "0",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          height: "100%",
                          overflow: "hidden",
                          display: "inline-block",
                          filter: "brightness(0.4)"
                        }}
                        autoPlay
                        loop
                        
                      >
                        <source
                          src="https://kriscodes2.s3.amazonaws.com/video/brand-loyalty.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <h2 className="description text-white">Brand</h2>
                    <h1 className="title text-white">Loyalty</h1>
                    <br />
                    <Button
                      className="btn-simple btn-neutral text-white"
                      color="default"
                      href="/contact-me"
                    >
                      how to earn trust
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md="5">
              <Card className="card-blog card-background">
                  <CardBody>
                    <div
                      style={{
                        zIndex: "-1",   
                        right: "0",       
                        left: "0",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        display: "inline-block",
                      }}
                    >
                      <video
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                          height: "100%",
                          left: "0",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          height: "100%",
                          display: "inline-block",
                          filter: "brightness(0.4)"
                        }}
                        autoPlay
                        loop
                        
                      >
                        <source
                          src="https://kriscodes2.s3.amazonaws.com/video/effective-sales-funnel.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <h2 className="description text-white">Sales</h2>
                    <h1 className="title text-white">Funnel</h1>
                    <br />
                    <Button
                      className="btn-simple btn-neutral text-white"
                      color="default"
                      href="/contact-me"
                    >
                      how to funnel sales
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="7">
              <Card className="card-blog card-background">
                  <CardBody>
                    <div
                      style={{
                        zIndex: "-1",   
                        right: "0",       
                        left: "0",
                        top: "0",
                        bottom: "0",
                        left: "0",
                        display: "inline-block",
                      }}
                    >
                      <video
                        style={{
                          position: "absolute",
                          zIndex: "-1",
                          height: "100%",
                          left: "0",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          height: "100%",
                          display: "inline-block",
                          filter: "brightness(0.4)"
                        }}
                        autoPlay
                        loop
                        
                      >
                        <source
                          src="https://kriscodes2.s3.amazonaws.com/video/higher-engagement.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <h2 className="description text-white">Engage with</h2>
                    <h1 className="title text-white">Customers</h1>
                    <br />
                    <Button
                      className="btn-simple btn-neutral text-white"
                      color="default"
                      href="/contact-me"
                    >
                      how to grow relationships
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>{" "}
    </>
  );
}
