import React from "react";
import { Row, Col, Card } from "reactstrap";
import "assets/css/arrow.css";
import "assets/css/gradient-background.css";

export default function FirstBackground() {
  return (
    
    <div className="fader d-flex justify-content-center mt-0 mb-0 pb-0" width="100%" >
     
     <Card  className="card mx-5 mt-5" style={{maxWidth: "45rem", height: "30rem", minWidth: "18rem"}} >
    
       <Row className="d-flex justify-content-center my-0">
       
        <Col                   
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6} 
          className="mx-auto mt-5">
                      <Row className="animate__animated animate__fadeIn">
            <h2 className="text-secondary title mx-auto my-0" >I can help you </h2>         
          </Row>
          <Row className="animate__delay-1s animate__animated animate__fadeIn">
            <h2 className="text-warning title mx-auto my-0" >Boost Profits </h2>         
          </Row>
          <Row className="animate__delay-2s animate__animated animate__fadeIn">
            <h2 className="text-warning title mx-auto my-0" > Build Trust </h2>
          </Row>
          <Row className="animate__delay-3s animate__animated animate__fadeIn">
            <h2 className="text-warning title mx-auto my-0" > Get seen</h2>
          </Row>
          <img
            className={
              "animate__delay-3s animate__animated animate__fadeOut"
            }
            src="https://kriscodes2.s3.amazonaws.com/img/webp/kris-christopher.webp"
            alt="Photo of Kris"
            height={280}
            width={300}
          ></img></Col>
        </Row>
        </Card>
        
       
      </div>
  );
}
