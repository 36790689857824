import React, { useEffect, useRef } from "react";
import DemoFooter from "components/Footers/MyFooter.js";
import DomainChecker from "./PresentationSections/DomainChecker.js";
// import Sections from "./PresentationSections/Sections.js";
import FreeDemo from "./PresentationSections/FreeDemo.js";
import RenderSwitch from "./PresentationSections/RenderSwitch.js";
import ColorNavbar from "components/Navbars/ColorNavbar.js";

export default function Presentation() {
  const wrapper = useRef(null);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("presentation-page");
    return function cleanup() {
      document.body.classList.remove("presentation-page");
    };
  }, []);

  return (
    <>
      <ColorNavbar />
      <div className="wrapper" ref={wrapper} id="presentation-page">
        <RenderSwitch />
        <div className="space-110" />
        <FreeDemo />
        <DomainChecker />
        <div className="space-110" />

        <div className="space-110" />
        {/* <Sections /> */}

        <DemoFooter />
      </div>
    </>
  );
}
