import React, { useState } from "react";
// reactstrap components
import { Button, Card, Input, Container, Row, Col } from "reactstrap";
// core components
import { NavLink } from "react-router-dom";

export default function DomainChecker() {
  // state
  const [domain, setDomain] = useState(" ");
  const [clicked, setClicked] = useState(false);
  const [isTaken, setIsTaken] = useState(false);
  const [btnColor, setBtnColor] = useState("warning");
  const [result, setResult] = useState(false);
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;

    document.body.classList.add("about-us");
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  }, []);

  // 2 way binding on domain input and input validation
  React.useEffect(() => {
    let little = domain.toLowerCase();
    let pattern =
      /^[a-z0-9]+\.(com|org|net|mil|edu|io|gov|info|biz|studio|com.au|co.uk|de|ca|me|us|eu|academy|codes)$/i;
    if (little.match(pattern)) {
      setResult(true);
      setBtnColor("success");    
    } else if (!domain.match(pattern)) {
      setResult(false);
    }   
  }, [domain]);

  // API request
  function GetDomain() {
    fetch(
      `${process.env.DOMAIN_ENDPOINT}&domainName=${domain}&credits=DA`
    )
      .then((response) => {
        return response.json();
      })  
      .then((data) => {
        if (data.DomainInfo.domainAvailability === "UNAVAILABLE") {
          setIsTaken(true);
          setClicked(true);
        } else {
          setIsTaken(false);
          setClicked(true); 
        }
      });
  }
 
  // Handler Functions
  const onClickHandler = (e) => {
    e.preventDefault();
    if (result === true) {
      GetDomain();
    } else return;
  };

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter") {
      onClickHandler(e);
    } else return;
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    setClicked(false);
    setIsTaken(false);
    setDomain("");
    setBtnColor("warning");
    setResult(false);
  };
  const onChangeHandler = (e) => {
    e.preventDefault();
    setDomain(e.target.value);
  };

  // turnary divs
  const takenDiv = (
    <div className="wrapper">
      <Card>
        {" "}
        <Row className="align-items-center text-center">
          <Col className="ml-auto mr-auto">
            <img
              width="75%"
              alt="Great! Your domain is available"
              src="https://kriscodes2.s3.amazonaws.com/img/webp/your-domain-is-already-taken.webp "
            />
            <br />
            <h3>Sorry, it looks like</h3>
            <h2 className="title pt-3">{domain}</h2>
            <h2 className="title">
              <strong className="text-danger">Is Already Taken</strong>
            </h2>
            <h4 className="mx-5">Give it another try!</h4>
          </Col>
        </Row>
        <Row className="row-input mx-auto my-3">
          <Col md="4" xs="6">
            <Button size="sm" onClick={(e) => onResetHandler(e)}>
              Reset
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );

  const availableDiv = (
    <div className="wrapper">
      <Card>
        {" "}
        <Row className="align-items-center text-center">
          <Col className="ml-auto mr-auto">
            <h2 className="text-success pt-3">{domain}</h2>

            <img
              width="75%"
              alt="Great! Your domain is available"
              src="https://kriscodes2.s3.amazonaws.com/img/webp/your-domain-is-available.webp"
            />
            <br />
            <h3>Congratulations!!</h3>
            <h1 className="title">
              {" "}
              <strong className="text-success">It's Available</strong>
            </h1>
            <h4 className="mx-5">Let's get started by picking a look for your website.</h4>
          </Col>
        </Row>
        <Row className="row-input mx-auto my-3">
          <Col md="4" xs="6">
            <Button size="sm" onClick={(e) => onResetHandler(e)}>
              Reset
            </Button>
          </Col>

          <Col md="4" xs="6">
            <NavLink to="/contact-me">
              <Button size="sm" color="success">
                Themes
              </Button>
            </NavLink>
          </Col>
        </Row>
      </Card>
    </div>
  );

  // returned JSX
  return clicked === true ? (
    isTaken === true ? (
      takenDiv
    ) : (
      availableDiv
    )
  ) : (
    <div className="wrapper" ref={wrapper}>
      <Container>
        <Card className="ml-auto mr-auto mt-35">
          <Row>
            <video
              preload="auto"
              className="align-items-center align-center mt-4 mb-3 ml-auto mr-auto"
              style={{ width: "75%" }}
              autoPlay={true}
              loop="loop"
              muted="muted"
              playsInline={true}
            >
              <source
                src="https://kriscodes2.s3.amazonaws.com/video/choose-a-domain-name.mp4"
                type="video/mp4"
              />
            </video>
          </Row>
          <Row className="row-input">
            <Col md="8" xs="12" className="mx-auto my-auto" >
              <Input
              className="mx-auto my-auto"
                maxLength={64}
                style={{minWidth: "275px"}}
                bsSize="lg"
                onKeyUp={(e) => onKeyUpHandler(e)}
                valid={result}
                autoComplete="off"
                onChange={(e) => onChangeHandler(e)}
                aria-label="Enter your domain name choice"
                id="url"
                name="url"
                placeholder="example.com"
                type="text"
              />
            </Col>
          </Row>
          <Row className="row-input ml-2  pl-sm-5 mx-md-auto my-3">
          <Col  >
         
         <Button
           size="lg"
           id="submit"
           onClick={(e) => onClickHandler(e)}
           color={btnColor}
         >
           Check
         </Button>
        
       </Col>
            <Col md="4" xs="6" className="mr-5 pr-5">
            <NavLink to="/contact-me">
            <Button style={{height: "3rem", width: "140px", padding:0}} size="lg" color="info">
              Register
            </Button>
            </NavLink>
            </Col>

          </Row>
        </Card>
      </Container>
    </div>
  );
}

<div className="section about-description wrapper">
  <img
    alt="..."
    className="path path4"
    src={require("assets/img/path4.png").default}
  />
  <Container>
    <Row className="mb-5">
      <Col className="ml-auto mr-auto text-center" md="8">
        <h2 className="title">First Website?</h2>
        <h4 className="description">
          No worries. Start fast and inexpensive with a 5 page website. You can
          always add more features and the code is yours. Never get locked in
          and exploit unlimited options. Website specials starting at only $299
          and $9.99 a month for unlimited hosting.
        </h4>
      </Col>
    </Row>
    <Row>
      <Col className="mx-auto" md="8">
        <video
        preload="auto"
          style={{ width: "100%" }}
          autoPlay={true}
          loop="loop"
          muted="muted"
          playsInline={true}
          className="d-block"
        >
          <source
            src="https://kriscodes2.s3.amazonaws.com/video/custom-website.mp4"
            type="video/mp4"
          />
        </video>
      </Col>
    </Row>
  </Container>
</div>;
