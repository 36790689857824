import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";



// reactstrap components
import {
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

function AuthNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [myUser, setMyUser] = useState(null);
  let navigate = useNavigate();



 
 async function mySignOut() {
    navigate("/Presentation")
  }

  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-dark");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Navbar
        dark
        className={
          "navbar sticky-top justify-content-sm-start fixed-top " + navbarColor
        }
        expand="lg"
      >
        <div className="navbar-translate">
          <NavbarBrand
            onClick={(e) => scrollToTop(e)}
            id="tooltip6619950104"
            className="ml-0 pl-0 d-flex justify-content-sm-start"
          >
            <img
              className="ml-0 pl-0 d-flex justify-content-sm-start"
              src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"
              alt="Logo KrisCodes2"
              height="25rem"
            />
          </NavbarBrand>
          <UncontrolledTooltip delay={0} target="tooltip6619950104">
            Go To Top
          </UncontrolledTooltip>
          <button className="navbar-toggler" id="navigation">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <UncontrolledCollapse navbar toggler="#navigation">
          <div className="navbar-collapse-header">
            <Row>  
              <Col className="collapse-brand" xs="6">
                <a onClick={(e) => scrollToTop(e)} href="#Presentation">
                  <img
                    src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"
                    alt="into video for Kris Codes 2"
                  />
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="navigation">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
            {/* <UncontrolledDropdown nav>
              <DropdownToggle caret color="default" nav>
                <i
                  aria-hidden={true}
                  className="tim-icons icon-book-bookmark"
                />
                <p>My Website</p>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem to="/about-me" tag={Link}>
                  <i className="tim-icons icon-bulb-63" />
                  Domains
                </DropdownItem>
                <DropdownItem to="/blog-post" tag={Link}>
                  <i className="tim-icons icon-align-center" />
                  Themes
                </DropdownItem>
                <DropdownItem to="/blog-posts" tag={Link}>
                  <i className="tim-icons icon-chart-bar-32" />
                  Pages
                </DropdownItem>
                <DropdownItem to="/contact-me" tag={Link}>
                  <i className="tim-icons icon-square-pin" />
                  Add Ons
                </DropdownItem>
                <DropdownItem to="/landing-page" tag={Link}>
                  <i className="tim-icons icon-paper" />
                  Upload
                </DropdownItem>
                <DropdownItem to="/pricing" tag={Link}>
                  <i className="tim-icons icon-coins" />
                  Questionaire
                </DropdownItem>
                <DropdownItem to="/ecommerce" tag={Link}>
                  <i className="tim-icons icon-basket-simple" />
                  Contact Me
                </DropdownItem>
                <DropdownItem to="/chat-page" tag={Link}>
                  <i className="tim-icons icon-email-85" />
                  Live Chat
                </DropdownItem>
                <DropdownItem to="http://admin.kriscode.com/" tag={Link}>
                  <i className="tim-icons icon-coins" />
                  Analytics
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            <UncontrolledDropdown>
              <DropdownToggle caret color="default" nav>
                <i aria-hidden={true} className="tim-icons icon-settings" />
                Account
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem to="/account-settings" tag={Link}>
                  <i className="tim-icons icon-lock-circle" />
                  Account Settings
                </DropdownItem>
                <DropdownItem to="/profile-page" tag={Link}>
                  <i className="tim-icons icon-tablet-2" />
                  Profile Page
                </DropdownItem>
                <DropdownItem to="/ecommerce" tag={Link}>
                  <i className="tim-icons icon-molecule-40" />
                  eCommerce
                </DropdownItem>
                <DropdownItem to="/invoice-page" tag={Link}>
                  <i className="tim-icons icon-notes" />
                  Invoice Page
                </DropdownItem>
                <DropdownItem to="/checkout-page" tag={Link}>
                  <i className="tim-icons icon-basket-simple" />
                  Checkout Page
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <h6  className="mt-3 text-success"><em>{myUser}</em></h6>
            </NavItem>
            <NavItem>
              <Button  onClick={() => mySignOut()}>Sign Out</Button>           
            </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </Navbar>
    </>
  );
}

export default AuthNavbar;
