import React from "react";

export default function IntroHeader() {
  return (
    <div >
      <video
         width={"100%" }
         height={"auto"}
        autoPlay={true}
        loop="loop"
        muted="muted"
        playsInline="playsinline"
      >
        <source
          src="https://kriscodes2.s3.amazonaws.com/video/website-design.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
}
