///////////////////////////////////////////////////////////    Imports          /////////////////////////////////////////////////////////
import LittleFooter from "components/Footers/LittleFooter";
import LittleNavbar from "components/Navbars/LittleNavbar";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactToPrint } from "react-to-print";
import GettingToKnowYou from "./GettingToKnowYou";

// reactstrap components
import {
  Button,
  CardBody,
  Col,
  Card,
  CardTitle,
  CardFooter,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Progress,
  Row,
  Alert,
  ModalBody,
} from "reactstrap";

///////////////////////////////////////////////////////////////////   Start main function     ////////////////////////////////////////////

export default function BusinessSnapshot(props) {
  const [clicked, setClicked] = React.useState(false);
  const [btnHidden, setBtnHidden] = React.useState(true);
  const [businessNameValid, setBusinessNameValid] = React.useState(false);
  const [businessName, setBusinessName] = React.useState("");
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [buttonsVisible, setButtonsVisible] = React.useState(true);
  const [answer1, setAnswer1] = React.useState("");
  const [answer2, setAnswer2] = React.useState("");
  const [answer3, setAnswer3] = React.useState("");
  const [answer4, setAnswer4] = React.useState("");
  const [answer5, setAnswer5] = React.useState("");
  const [answer6, setAnswer6] = React.useState("");
  const [answer7, setAnswer7] = React.useState("");
  const [answer8, setAnswer8] = React.useState("");
  const [answer9, setAnswer9] = React.useState("");
  const [answer10, setAnswer10] = React.useState("");

  // React.useEffect(() => {
  //   if (answer1 === "") {
  //     if (answers[answer1]) {
  //     setAnswer1(answers[answer1])
  //   }
  // }
  //   return () => {
  //     cleanup
  //   }
  // }, [answer1])

  const q = [
    {
      id: 1,
      question: "Why Are You Doing This?",
      explaination: "What is your purpose, your passion, your raison d'être?",
      checkbox: [
        "To solve a problem",
        "To help others",
        "Financial gain",
        "Passion",
        "Social Justice",
      ],
      answer: answer1,
    },
    {
      id: 2,
      question: "What problem does your business solve?",
      explaination: "What is your value proposition?",
      checkbox: [
        "Saves time",
        "Saves money",
        "Increases health",
        "Entertainment",
      ],
      answer: answer2,
    },
    {
      id: 3,
      question: "What do you do better than anyone else?",
      explaination: "Don't be shy. Why should customers do business with you?",
      checkbox: [],
      answer: answer3,
    },
    {
      id: 4,
      question: "How does your business generate income?",
      explaination: "Do you provide subscriptions, products or services?",
      checkbox: ["Provide a service", "Sell a product", "Subscriptions"],
      answer: answer4,
    },
    {
      id: 5,
      question: "What is your pricing strategy and why?",
      explaination: "Are there specials, coupons or discount memberships?",
      checkbox: ["Specials", "Coupons", "Memberships"],
      answer: answer5,
    },
    {
      id: 6,
      question: "What is your social media strategy?",
      explaination: "Are you a digital marketing genius or starting from zero?",
      checkbox: [
        "I pay professionals",
        "I run ad campaigns",
        "I manage one social media account",
      ],
      answer: answer6,
    },
    {
      id: 7,
      question: "Who is your aveage customer?",
      explaination:
        "Give them a name, age, gender, demographic, 'the works'. Tell me about them.",
      checkbox: [],
      answer: answer7,
    },
    {
      id: 8,
      question: "Who are your most valuable customers?",
      explaination: "Which ones are the most costly and why",
      checkbox: [],
      answer: answer8,
    },
    {
      id: 9,
      question: "How do you get referrals?",
      explaination: "How do people usually find out about your business?",
      checkbox: ["Word of mouth", "Advertising", "Social Media"],
      answer: answer9,
    },
    {
      id: 10,
      question: "What goals do you have from purchasing a custom website?",
      explaination:
        "Get specific. 15% revenue increase in 90 days perhaps? Be honest and clear.",
      checkbox: [],
      answer: answer10,
    },
  ];

  ///////////////////////////////////////////////////  Content Variables /////////////////////////////////////////////////////////////
  // businessName validity
  React.useEffect(() => {
    const emailPattern = /^.{2,}$/g;
    if (businessName.match(emailPattern)) {
      setBusinessNameValid(true);
    } else if (!businessName.match(emailPattern)) {
      setBusinessNameValid(false);
    }
    return () => {
      setBusinessNameValid(false);
    };
  }, [businessName]);

  React.useEffect(() => {
    if ((businessNameValid === true) && (clicked === false) ) {
     

     
      console.log("ready for data transfer");
    }
    return () => {
      setTimeout(() => setClicked(false), 2000)
    };
  }, [clicked]);

  /////////////////////////////////////////////////////////////////////////////////////            Slider functions  ////////////////////////

  const next = () => {
    if (animating) {
      return;
    }
    const nextIndex = activeIndex === 3 ? activeIndex : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = (e) => {
    e.preventDefault();

    if (animating) {
      return;
    }
    const previousIndex = activeIndex === 0 ? previousIndex : activeIndex - 1;
    setActiveIndex(previousIndex);
  };
  ///////////////////////////////////////////////////  Handler Functions /////////////////////////////////////////////////////////////
  const onEmailChangeHandler = (e) => {
    e.preventDefault();
    setBusinessName(e.target.value);
  };

  const slide1 = (
    <>
            <Row style={{ width: "100%" }} className="m-0 pt-5 pl-5">
          <h5>
            Step 1
          </h5>
        </Row>
      <CardTitle className="h1-seo  mt-5 mb-0 pb-0 text-white text-center">
        <h2 className="text-success mx-auto mt-5 mb-0 p-3">
          Let's start with some info
        </h2>
      </CardTitle>
      <CardBody className=" mx-3 mt-0  py-0">
        <Row>
          <h3 className="mx-auto">Quick Business Overview</h3>
        </Row>
        <Row style={{ width: "100%" }}>
          <FormGroup  className="mx-auto">
            <label className="text-white">
              Enter your business name to get started
            </label>
            <InputGroup>
              <Input
                valid={businessNameValid}
                placeholder="YOUR BUSINESS NAME..."
                className="text-white"
                id="question-form"
                onChange={(e) => onEmailChangeHandler(e)}
                name="businessName"
                rows="1"
                type="text"
              />
            </InputGroup>
          </FormGroup>
        </Row>
        <Row className="my-3 mx-auto p-0" style={{ width: "100%" }}>
          <Button
            size="sm"
            id="question-form"
            onClick={(e) => next(e)}
            className="btn-round pull-right mx-auto"
            color={businessNameValid ? "success" : "default"}
          >
            Get Started
          </Button>
        </Row>
      </CardBody>
    </>
  );

  const slide2 = (
    <GettingToKnowYou
      //  answers={answers}
      onNextHandler={next}
    />
  );

  const BusinessPlanQuestion = q.map((item) => {
    return <Row>{item.question}</Row>;
  });

    const BusinessPlanAnswer = q.map((item) => {
      return (
            <Row>{item.answer}</Row>
  );
  })

  const BusinessPlanPDF = (
    <>
      <Card>
        <CardTitle>
          <h1>My Business Plan Cheat Sheet</h1>
        </CardTitle>
        <CardBody>{BusinessPlanQuestion}</CardBody>
        <CardFooter>
          <Link to="/Presentation">Need a website?</Link>
        </CardFooter>
      </Card>
    </>
  );

  const slide3 = (
    <>
      <CardTitle className="h1-seo  mt-5 mb-0 pb-0 text-white text-center">
        <h2 className="text-white">Step 1 Complete</h2>
      </CardTitle>
      <CardBody className=" mx-3 mt-0  py-0">
     
        <Button onClick={() => setClicked(true)}>
          Submit Answers
        </Button>
        <Alert isOpen={clicked} color="success" role={alert} >
        <ModalBody >
        <h1 className="text-darker">
          Thank you. I got your info. Lets move on...
        </h1>
        </ModalBody>
      </Alert>
        {/* <div>
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={BusinessPlanPDF}
      />
      <ComponentToPrint ref={BusinessPlanPDF} /> 
    </div> */}
      </CardBody>
    </>
  );

  const items = [
    {
      id: 1,
      content: slide1,
    },
    {
      id: 2,
      content: slide2,
    },
    {
      id: 3,
      content: slide3,
    },
  ];

  const slides = items.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        activeIndex={activeIndex}
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        {item.content}
      </CarouselItem>
    );
  });

  const getPdf = <Button onClick={() => next()} />;
  return (
    <>
    <Card className="mx-auto" style={{maxWidth: "600px"}}>
    <Carousel
      className="pb-0"
      activeIndex={activeIndex}
      next={() => next}
      previous={() => previous}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      {slides}
    </Carousel>
    </Card>
    </>
  );
}
