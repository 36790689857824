import React from "react";
import "../../assets/css/arrow.css";
import "../../assets/css/video.css";
// reactstrap components
import {
  Container,
  Col,
} from "reactstrap";

export default function ContactHeader() {
  return (
    <>
      <div className="header header-4">
        <div className="header-wrapper">
          <div className="page-header header-video header-filter">
            <div className="video-container" style={{height: "80vh"}} />
            <video
              className="video-bg"
              autoPlay={true}
              loop="loop"
              muted="muted"
              playsInline="playsinline"
              preload="auto"
            >
              <source
                src="https://kriscodes2.s3.amazonaws.com/video/typing.mp4"
                type="video/mp4"
              />
            </video>
            <Col className="mx-auto mt-5">
              <div className="animate__animated animate__animated animate__slow animate__jackInTheBox text-center ">
                <img
                  alt="logo for Kris Codes Too"
                  src="https://kriscodes2.s3.amazonaws.com/img/logo-mini.png"
                ></img>
              </div>
              <Container className="text-center mx-auto mt-0">
                <div className="video-text">       
                  <h3
                    className="animate__animated animate__delay-1s animate__animated animate__slow animate__rollIn title text-dark bg-white mx-auto"
                    style={{ maxWidth: "200px", fontSize: "35px" }}
                  >
                   Let's talk
                  </h3>
                </div>
              </Container>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}
