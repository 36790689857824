// src/components/ResumePage.js

import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Button
} from "reactstrap";
import ColorNavbar from "components/Navbars/ColorNavbar";
import DemoFooter from "components/Footers/MyFooter";

const Resume = () => {
  return (
    <>
      <ColorNavbar />
      <Container className="mt-5">
        <Row>
          <Col md="8" className="mx-auto">
            <Card className="mb-5">
              <CardHeader className="text-center">
                <CardTitle tag="h3">Kris Christopher</CardTitle>
                <CardText>Aguadilla, Puerto Rico | 787.631.6775 | kris923@gmail.com | www.MP4viewer.com</CardText>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" className="text-primary">Professional Summary</CardTitle>
                <CardText>
                  Accomplished Senior Full-Stack Developer with extensive experience in corporate and entrepreneurial environments. Proven track record in delivering high-performance software solutions and leading technical teams. Specialized in NextJS, React, AWS, and cloud-based application development. Seeking to leverage my skills and experience as a Senior NextJS Developer to drive success for your business.
                </CardText>
                <CardTitle tag="h5" className="text-primary mt-4">Professional Experience</CardTitle>
                <CardText>
                  <strong>Senior Full Stack Engineer, Collins Aerospace</strong><br/>
                  <em>March 2024 - Present</em><br/>
                  <ul>
                    <li>Leading the DevSecOps team to create testing pipelines for DO-178C and DO-330 standards.</li>
                    <li>Developed orchestration for testing Black Hawk helicopter systems using GitLab runners.</li>
                    <li>Utilized Coverity, Polyspace, VectorCast, ALM/Jira, and on-premise Kubernetes clusters for testing environments.</li>
                    <li>Contributed to NextJS and TypeScript projects, enhancing functionality and performance.</li>
                  </ul>
                  <strong>Full Stack Engineer, Lockheed Martin Aeronautics Division</strong><br/>
                  <em>March 2022 - February 2024</em><br/>
                  <ul>
                    <li>Architected and developed a consolidated ReactJS application integrating functionalities of 190 internal applications for senior military personnel to track operational readiness of aircraft.</li>
                    <li>Enhanced operational decision-making by providing a comprehensive dashboard for real-time tracking of F-16s, F-22s, F-35s, and C-130J readiness.</li>
                    <li>Led development of Vision, a React application for tracking parts status, improving maintenance efficiency.</li>
                    <li>Implemented coding standards and best practices ensuring maintainable and secure code.</li>
                    <li>Spearheaded and facilitated the adoption of Redis for the first time in Aeronautics.</li>
                  </ul>
                  <strong>Founder, KrisCodes2 LLC</strong><br/>
                  <em>2021 - Present</em><br/>
                  <ul>
                    <li>Launched and developed MP4 Viewer, a NextJS 14.2 application using AWS and MUI.</li>
                    <li>Managed AWS cloud infrastructure, integrated security best practices, and developed scalable serverless applications.</li>
                    <li>Implemented CI/CD pipelines and conducted thorough unit testing to ensure robust software delivery.</li>
                    <li>Created and maintained GraphQL APIs facilitating efficient data management and retrieval.</li>
                  </ul>
                  <strong>Founder, Grow-Nanny Incorporated</strong><br/>
                  <em>2018 - 2021</em><br/>
                  <ul>
                    <li>Designed and implemented cloud solutions using AWS for diverse customer needs.</li>
                    <li>Developed wireless solar-powered VPD sensors and managed global beta testing.</li>
                    <li>Built serverless React front-end applications with GraphQL APIs optimizing performance and scalability.</li>
                    <li>Integrated security protocols using AWS services such as Certificate Manager and Cognito.</li>
                  </ul>
                </CardText>
                <CardTitle tag="h5" className="text-primary mt-4">Skills</CardTitle>
                <CardText>
                  <ul>
                    <li><strong>Technologies:</strong> React, NextJS, TypeScript, JavaScript, ExpressJS, GraphQL, NodeJS, Java, Spring Boot, MongoDB, SQL, NoSQL</li>
                    <li><strong>Cloud Platforms:</strong> AWS (Amplify, Lambda, API Gateway, S3, RDS, DynamoDB, IoT, Lex, Polly), Kubernetes</li>
                    <li><strong>Tools:</strong> GitLab, Coverity, Polyspace, VectorCast, ALM/Jira, Docker</li>
                    <li><strong>Architecture:</strong> UML, C4 Diagrams, microservices, serverless architecture</li>
                    <li><strong>Development Practices:</strong> Unit testing, CI/CD, Agile methodologies, DevOps practices</li>
                    <li><strong>Soft Skills:</strong> Leadership, mentoring, collaboration, problem-solving, effective communication</li>
                  </ul>
                </CardText>
                <CardTitle tag="h5" className="text-primary mt-4">Education & Certifications</CardTitle>
                <CardText>
                  <ul>
                    <li><strong>AAS Fire Science Technology, Glendale Community College</strong><br/>
                      Graduated with Honors, 3.84 GPA, President of Student Government (106 credits towards bachelors)</li>
                    <li><strong>Online Courses & Certifications</strong><br/>
                      A-Cloud-Guru, Udemy, Sundog Education, Coding with Mosh (Over 50 certificates)</li>
                  </ul>
                </CardText>
                <CardFooter className="text-center">
                  <a href="https://kriscodes2.s3.amazonaws.com/files/Resume.pdf" target="_blank" rel="noopener noreferrer">
                    <Button color="primary">Download Full Resume (PDF)</Button>
                  </a>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DemoFooter />
    </>
  );
};

export default Resume;
