import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  CardFooter,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import ColorNavbar from "components/Navbars/ColorNavbar";
import DemoFooter from "components/Footers/MyFooter";

export default function AboutMe() {
  return (
    <>
      <ColorNavbar />
      <img
        alt="Kris Codes Too Logo"
        style={{ position: "absolute", height: "100vh", width: "100%" }}
        src="https://kriscodes2.s3.amazonaws.com/img/webp/playa-punta-borinquen-kriscodes2.webp"
      />
      <div className="page-header">
        <div className="content-center">
          <Card
            className="card-profile profile-bg my-5 mx-auto"
            style={{ position: "relative", maxWidth: "600px" }}
          >
            <CardHeader
              style={{
                backgroundImage:
                  "url(https://kriscodes2.s3.amazonaws.com/img/webp/team.webp)",
              }}
            >
              <div className="card-avatar">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className="img img-raised"
                    src="https://kriscodes2.s3.amazonaws.com/img/webp/kris-christopher.webp"
                  />
                </a>
              </div>
            </CardHeader>
            <CardBody className="my-5 py-5">
              <CardTitle tag="h3" className="text-success">Kris Christopher</CardTitle>

              <h6 className="category text-primary">
                Senior Full-Stack Developer, AWS Solutions Architect
              </h6>

              <p className="card-description mx-4 text-white">
                Accomplished Full-Stack Web Developer with extensive experience in both corporate and entrepreneurial environments. <br/>
                Proven track record of achieving high performance and delivering quality software solutions.
              </p>

              <p className="card-description mx-4 text-white">
                Recent roles include Full Stack Engineer at Lockheed Martin and Senior DevSecOps Engineer at Collins Aerospace.<br/>
                Specialized in developing scalable, secure web applications using JavaScript, React, NextJS, and AWS.
              </p>

              <p className="card-description mx-4 text-white">
                Ready to leverage my skills and experience as a Senior NextJS Developer to help your business succeed.
              </p>

              <Link to="/resume">
                <Button color="primary" className="mt-4">View My Resume</Button>
              </Link>
            </CardBody>
          </Card>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}
