import React from "react";

// reactstrap components
import {
  Button,
  CardBody,
  Col,
  Card,
  CardTitle,
  CardFooter,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Progress,
  Row,
} from "reactstrap";


export default function GettingToKnowYou(props) {
  const [clicked, setClicked] = React.useState(false);
  const [btnColor, setBtnColor] = React.useState("warning");
  const [btnHidden, setBtnHidden] = React.useState(true);
  const [formStateValidity, setFormStateValidity] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState(1);
  const [currentExplaination, setCurrentExplanation] = React.useState(1);
  const [answer1, setAnswer1] = React.useState("");
  const [answer2, setAnswer2] = React.useState("");
  const [answer3, setAnswer3] = React.useState("");
  const [answer4, setAnswer4] = React.useState("");
  const [answer5, setAnswer5] = React.useState("");
  const [answer6, setAnswer6] = React.useState("");
  const [answer7, setAnswer7] = React.useState("");
  const [answer8, setAnswer8] = React.useState("");
  const [answer9, setAnswer9] = React.useState("");
  const [answer10, setAnswer10] = React.useState("");
  const [visibleButton, setVisibleButton] = React.useState(
    <Button
      size="sm"
      id="question-form"
      onClick={() => next}
      className="btn-round pull-right"
      color="default"
    >
      next
    </Button>
  );
 
  const answers = [
    {
      id: 1, 
      answer: answer1
    },
    {
      id: 2, 
      answer: answer2
    },
    {
      id: 3, 
      answer: answer3
    },
    {
      id: 4, 
      answer: answer4
    },
    {
      id: 5, 
      answer: answer5
    },
    {
      id: 6, 
      answer: answer6
    },
    {
      id: 7, 
      answer: answer7
    },
    {
      id: 8, 
      answer: answer8
    },
    {
      id: 9, 
      answer: answer9
    },
    {
      id: 10, 
      answer: answer10
    },
  ]

  const q = [
    {
      id: 1,
      question: "Why Are You Doing This?",
      explaination: "What is your purpose, your passion, your raison d'être?",
      checkbox: [
        "To solve a problem",
        "To help others",
        "Financial gain",
        "Passion",
        "Social Justice",
      ],
      answer: answer1
    },
    {
      id: 2,
      question: "What problem does your business solve?",
      explaination: "What is your value proposition?",
      checkbox: [
        "Saves time",
        "Saves money",
        "Increases health",
        "Entertainment",
      ],
      answer: answer2
    },
    {
      id: 3,
      question: "What do you do better than anyone else?",
      explaination: "Don't be shy. Why should customers do business with you?",
      checkbox: [],
      answer: answer3
    },
    {
      id: 4,
      question: "How does your business generate income?",
      explaination: "Do you provide subscriptions, products or services?",
      checkbox: ["Provide a service", "Sell a product", "Subscriptions"],
      answer: answer4
    },
    {
      id: 5,
      question: "What is your pricing strategy and why?",
      explaination: "Are there specials, coupons or discount memberships?",
      checkbox: ["Specials", "Coupons", "Memberships"],
      answer: answer5
    },
    {
      id: 6,
      question: "What is your social media strategy?",
      explaination: "Are you a digital marketing genius or starting from zero?",
      checkbox: [
        "I pay professionals",
        "I run ad campaigns",
        "I manage one social media account",
      ],
      answer: answer6
    },
    {
      id: 7,
      question: "Who is your aveage customer?",
      explaination:
        "Give them a name, age, gender, demographic, 'the works'. Tell me about them.",
      checkbox: [],
      answer: answer7
    },
    {
      id: 8,
      question: "Who are your most valuable customers?",
      explaination: "Which ones are the most costly and why",
      checkbox: [],
      answer: answer8
    },
    {
      id: 9,
      question: "How do you get referrals?",
      explaination: "How do people usually find out about your business?",
      checkbox: ["Word of mouth", "Advertising", "Social Media"],
      answer: answer9
    },
    {
      id: 10,
      question: "What goals do you have from purchasing a custom website?",
      explaination:
        "Get specific. 15% revenue increase in 90 days perhaps? Be honest and clear.",
      checkbox: [],
      answer: answer10
    },
  ];



  const inputField = (
    <div style={{ width: "100%" }} className="p-0 m-0">
      <Card color="secondary" className="p-0 m-0" style={{ width: "100%" }}>
        <Form
          onSubmit={(e) => onSubmitHandler(e)}
          className="p-0 m-0"
          id="question-form"
          method="post"
          role="form"
        >
          <CardBody className="p-0 my-1 mx-2">
            <FormGroup>
              <label className="text-dark">Explain in detail:</label>
              <Input
                bsSize="lg"
                className="text-darker"
                autoComplete="false"
                id="question-form"
                onChange={(e) => onMessageChangeHandler(e)}
                name="message"
                rows="10"
                type="textarea"
              />
            </FormGroup>
          </CardBody>
        </Form>
      </Card>
    </div>
  );

  const next = () => {
    if (animating) {
      return;
    }
    const nextIndex =
      activeIndex === q.length - 1 ? activeIndex : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) {
      return;
    }
    const previousIndex = activeIndex === 0 ? q.length - 1 : activeIndex - 1;
    setActiveIndex(previousIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) {
      return;
    }
    setActiveIndex(newIndex);
  };

  const onMessageChangeHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  React.useEffect(() => {
    if (btnHidden) {
      setVisibleButton(
        <Button
          size="sm"
          id="question-form"
          onClick={() => next()}
          className="btn-round btn pull-right"
          color="default"
        >
          Next
        </Button>
      );
    } else if (!btnHidden) {
      setVisibleButton(
        <Button
          onClick={() => next()}
          size="sm"
          id="question-form"
          type="submit"
          className="btn-round btn pull-right"
          color="success"
        >
          Submit
        </Button>
      );
    }
  }, [btnHidden]);

  React.useEffect(() => {
    switch (activeIndex) {
      case 0:
        setAnswer1(message);
        break;
      case 1:
        setAnswer2(message);
        break;
      case 2:
        setAnswer3(message);
        break;
      case 3:
        setAnswer4(message);
        break;
      case 4:
        setAnswer5(message);
        break;
      case 5:
        setAnswer6(message);
        break;
      case 6:
        setAnswer7(message);
        break;
      case 7:
        setAnswer8(message);
        break;
      case 8:
        setAnswer9(message);
        break;
      case 9:
        setAnswer10(message);
        break;

      default:
        break;
    }
  }, [message]);

  React.useEffect(() => {
    setProgress(activeIndex * 10);
    setTimeout(() => {
      setCurrentExplanation(q[activeIndex].explaination);
      setCurrentQuestion(q[activeIndex].question);
      

      if (activeIndex === q.length - 1) {
        setBtnHidden(false);
      }
    }, 670);

    return () => {
      setBtnHidden(true);
    };
  }, [activeIndex]);

    async function onSubmitHandler(e) {
      e.preventDefault();
      if (formStateValidity === true) { 
        setClicked(true);
      }
    }

  const slides = q.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Card
          className="card-raised mx-auto mt-0 pt-0"
          color="dark"
          style={{ width: "95%" }}
        >
          <Progress
            color="success"
            value={progress}
            className="mt-0 mb-0 mx-2"
          />

          <CardTitle className="h4-seo mt-2 mb-0 py-0 text-white text-center">
            <h4 className="text-white">{currentQuestion}</h4>
          </CardTitle>
          <CardBody className=" mx-3 my-0  py-0">
            <Row>
              <h5 className="text-info text-left my-0 p-0">
                <em>{currentExplaination}</em>
              </h5>
            </Row>
            <Row className="my-2 p-0 mx-auto">{inputField}</Row>
          </CardBody>
          <CardFooter className="p-0 m-0">
            <Row className="pb-2 m-0 p-0" style={{ width: "100%" }}>
              <Col xs="6" className="pull-left">
                <Button
                  size="sm"
                  id="question-form"
                  onClick={(e) => previous(e)}
                  className="btn-round pull-left"
                  color="default"
                >
                  previous
                </Button>
              </Col>
              <Col xs="6" className="pull-right">
                {btnHidden ? (
                  <Button
                    size="sm"
                    id="question-form"
                    onClick={(e) => next(e)}
                    className="btn-round pull-right"
                    color="default"
                  >
                    next
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    id="question-form"
                    className="btn-round pull-right"
                    color="success"
                    onClick={() => props.onNextHandler()}
                  >
                    Submit
                  </Button>
                )}
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </CarouselItem>
    );
  });

  
  return (
    <>
      <br />
      {/* <Progress color="success" value={progress} className="mt-0" /> */}

      <Carousel
        className="pb-1"
        activeIndex={activeIndex}
        next={() => next}
        previous={() => previous}
      >
        {slides}
      </Carousel>
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
